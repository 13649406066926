/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { CategoryDetailsComponentProps } from 'SourceComponent/CategoryDetails/CategoryDetails.type';
import { ReactElement } from 'Type/Common.type';

import './CategoryDescription.style';

/** @namespace Poprawa/Component/CategoryDescription/Component */
export class CategoryDescriptionComponent extends PureComponent<CategoryDetailsComponentProps> {
    static defaultProps: Partial<CategoryDetailsComponentProps> = {
        isCurrentCategoryLoaded: true,
    };

    renderCategoryDescription(): ReactElement {
        const {
            category: { description },
            isCurrentCategoryLoaded,
        } = this.props;
        const { isPrefetchValueUsed, actionName: { description: preloadDescription } } = window;

        if (isPrefetchValueUsed) {
            if (preloadDescription) {
                return <Html content={ preloadDescription } />;
            }

            return null;
        }

        if (!isCurrentCategoryLoaded) {
            return this.renderCategoryDescriptionPlaceholder();
        }

        if (!description) {
            return null;
        }

        return <Html content={ description } />;
    }

    renderCategoryDescriptionPlaceholder(): ReactElement {
        return (
            <p>
                <TextPlaceholder length={ TextPlaceHolderLength.LONG } />
            </p>
        );
    }

    render(): ReactElement {
        return (
            <article block="CategoryDescription">
                <div block="CategoryDescription" elem="Description">
                    { this.renderCategoryDescription() }
                </div>
            </article>
        );
    }
}

export default CategoryDescriptionComponent;
