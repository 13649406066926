/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Directions } from '@scandipwa/scandipwa/src/component/ChevronIcon/ChevronIcon.config';

import CategoryDescriptionComponent from 'Component/CategoryDescription/CategoryDescription.component';
import ChevronIcon from 'Component/ChevronIcon';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import { CategoryPageComponent as SourceCategoryPageComponent } from 'SourceRoute/CategoryPage/CategoryPage.component';

import './CategoryPage.override.style';

/** @namespace Poprawa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    renderFilterPlaceholder() {
        return (
            <div block="CategoryPage" elem="PlaceholderWrapper">
                <div block="CategoryPage" elem="PlaceholderContainer">
                    <h3 block="CategoryPage" elem="PlaceholderHeading">
                        { __('Filters') }
                    </h3>
                    <div block="CategoryPage" elem="PlaceholderList">
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                    </div>
                    <Loader isLoading />
                </div>
            </div>
        );
    }

    renderFilterButton() {
        const {
            isContentFiltered,
            totalPages,
            category: { is_anchor },
            isSearchPage,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            onFilterButtonClick,
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        if ((!isContentFiltered && totalPages === 0) || (!is_anchor && !isSearchPage) || !isCurrentCategoryLoaded) {
            return null;
        }

        return (
            <button
              block="CategoryPage"
              elem="Filter"
              onClick={ onFilterButtonClick }
            >
                <span>{ __('Filters') }</span>
                { this.renderFiltersCount() }
                <ChevronIcon direction={ Directions.RIGHT } />
            </button>
        );
    }

    render() {
        const hideProducts = !this.displayProducts();
        const { totalItems, category } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } }>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                    <CategoryDescriptionComponent category={ category } />
                </ContentWrapper>
            </main>
        );
    }
}

export default CategoryPageComponent;
