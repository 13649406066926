/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { formatPrice } from 'Util/Price';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/**
 * Product price
 * @class ProductPrice
 * @namespace Poprawa/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceContainer {
    getMinTierPrice(currency) {
        const { tierPrices } = this.props;

        if (tierPrices && tierPrices.length > 0) {
            const prices = tierPrices.map(({ final_price: { value = 0 } = {} }) => value);
            const pricesInlTax = tierPrices.map(({ final_price_incl_tax: { value = 0 } = {} }) => value);
            const minPrice = Math.min(...prices);
            const minPriceInclTax = Math.min(...pricesInlTax);

            return {
                currency,
                value: minPrice,
                valueFormatted: formatPrice(minPrice, currency),
                valueInclTax: minPriceInclTax,
                valueFormattedInclTax: formatPrice(minPriceInclTax, currency),
            };
        }

        return {
            currency,
            value: '',
            valueFormatted: '',
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
