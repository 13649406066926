/* eslint-disable react/forbid-component-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as CartIcon } from 'assets/icons/car.svg';

import { CartIconComponent as SourceCartIconComponent } from 'SourceComponent/CartIcon/CartIcon.component';

/** @namespace Poprawa/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIconComponent {
    render() {
        return (
            <CartIcon
              className="CartIcon"
            />
        );
    }
}

export default CartIconComponent;
