/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { DisplayProductPricesInCatalog } from 'Component/ProductPrice/ProductPrice.config';
import { ProductPriceComponent as SourceProductPriceComponent } from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace Poprawa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    renderSubPrice(price) {
        const {
            value: priceExclTax = 0,
            valueFormatted: priceExclTaxFormatted = 0,
        } = price;

        if (!priceExclTax && priceExclTax !== 0) {
            return null;
        }

        return (
            <span
              aria-label={ __('Current product price incl. tax') }
              block="ProductPrice"
              elem="SubPrice"
            >
                { __('Incl. tax: %s', priceExclTaxFormatted) }
            </span>
        );
    }

    renderOldPrice() {
        const {
            price: {
                originalPriceExclTax: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted,
                } = {},
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? 'highPrice' : undefined }
            >
                { originalPriceFormatted }
            </del>
        );
    }

    renderPriceWithOrWithoutTax(
        basePrice,
        taxPrice,
        label,
    ) {
        const {
            tierPrice: {
                valueFormatted: tierPriceFormatted,
                value: tierPriceValue,
                valueFormattedInclTax,
                valueInclTax,
            },
            price: {
                finalPrice: {
                    value,
                } = {},
            } = {},
            displayTaxInPrice,
        } = this.props;
        const { itemProp } = this.getCurrentPriceSchema();

        if (displayTaxInPrice === DisplayProductPricesInCatalog.INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) {
            return this.renderPrice(taxPrice, label);
        }

        if (!tierPriceFormatted || tierPriceValue >= (value || 0)) {
            return (
                <>
                    { this.renderPrice(taxPrice, label) }
                    { this.renderSubPrice(basePrice) }
                </>
            );
        }

        return (
            <>
                { <meta itemProp={ itemProp } content={ String(valueInclTax) } /> }
                { this.renderTierPrice(tierPriceFormatted, valueFormattedInclTax) }
            </>
        );
    }

    renderTierPrice(tierPriceFormatted, valueFormattedInclTax) {
        return (
            <>
                <span block="ProductPrice" elem="PriceValue">
                    { `${__('from')} ${tierPriceFormatted}` }

                </span>
                <span block="ProductPrice" elem="SubPrice">
                    { `${__('from')} ${valueFormattedInclTax} ${__('gross')}` }
                </span>
            </>
        );
    }

    renderPrice(price, label) {
        const {
            discountPercentage,
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0,
        } = price;

        const { itemProp, content } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if ((!priceValue && priceValue !== 0)) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                { this.renderPriceBadge(label) }
                <span
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    <meta itemProp={ itemProp } content={ String(content) } />
                    { priceFormatted }
                </span>
            </PriceSemanticElementName>
        );
    }

    render() {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
