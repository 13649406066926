/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { mapDispatchToProps, mapStateToProps, ProductContainer as SourceProductContainer } from 'SourceComponent/Product/Product.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Poprawa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    componentDidMount() {
        this.updateSelectedValues();
        this.updateAdjustedPrice();
        this.setDefaultQty();
    }

    findFirstTier() {
        const { product: { price_tiers } } = this.props;

        if (!price_tiers || !price_tiers.length) {
            return { quantity: 1 };
        }

        return price_tiers.reduce((acc, value) => (value.quantity < acc.quantity ? value : acc));
    }

    containerProps() {
        return {
            ...super.containerProps(),
            firstTier: this.findFirstTier(),
        };
    }

    setDefaultQty() {
        const tier = this.findFirstTier(this.props);

        if (tier) {
            this.setState({ quantity: tier.quantity });
        }
    }

    setQuantity(quantity, isVariant = false) {
        const { quantity: tierQty } = this.findFirstTier();
        const { quantity: oldQuantity = {} } = this.state;

        if (typeof quantity === 'object') {
            this.setState({ quantity: { ...(oldQuantity), ...quantity } });
        } else if (typeof isVariant === 'boolean' && isVariant && quantity) {
            this.setState({ quantity });
        } else if (quantity > oldQuantity) {
            this.setState({ quantity: oldQuantity + tierQty });
        } else if (quantity < oldQuantity && oldQuantity - tierQty >= tierQty) {
            this.setState({ quantity: oldQuantity - tierQty });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
