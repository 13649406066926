/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Suspense } from 'react';

import AddToCart from 'Component/AddToCart';
import { FieldType } from 'Component/Field/Field.config';
import ProductPrice from 'Component/ProductPrice';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { ProductComponent as SourceProductComponent } from 'SourceComponent/Product/Product.component';
import { ProductType } from 'SourceComponent/Product/Product.config';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { ValidationInputTypeNumber } from 'Util/Validator/Config';

export const FieldContainer = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/Field'),
);
export * from 'SourceComponent/Product/Product.component';

/** @namespace Poprawa/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    renderAddToCartButton(layout = CategoryPageLayout.GRID) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }

    renderSku() {
        const { getActiveProduct } = this.props;
        const { sku } = getActiveProduct();

        return (
            <p block={ this.className } elem="Sku" itemProp="sku">
                <span>{ __('SKU') }</span>
                <span>{ sku }</span>
            </p>
        );
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers,
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  isSchemaRequired
                />
            </div>
        );
    }

    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantity,
            inStock,
            product: { type_id },
            firstTier,
        } = this.props;

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <FieldContainer
                  type={ FieldType.NUMBER_WITH_CONTROLS }
                  attr={ {
                      id: 'item_qty',
                      name: 'item_qty',
                      defaultValue: quantity,
                      max: maxQuantity,
                      min: minQuantity,
                      disabled: quantity === (firstTier?.quantity ?? 1),
                  } }
                  validationRule={ {
                      inputType: ValidationInputTypeNumber.NUMERIC,
                      isRequired: true,
                      range: {
                          min: minQuantity,
                          max: maxQuantity,
                      },
                  } }
                  isDisabled={ !inStock }
                  mix={ { block: this.className, elem: 'Qty' } }
                  events={ { onChange: setQuantity } }
                  validateOn={ ['onChange'] }
                  value={ quantity }
                />
            </Suspense>
        );
    }
}

export default ProductComponent;
