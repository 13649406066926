/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { AddToCartComponent as SourceAddToCartComponent } from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace Poprawa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCartComponent {
    render() {
        const {
            mix,
            layout,
            isDisabled,
            isAdding,
            handleButtonClick,
        } = this.props;

        return (
            <button
              onClick={ handleButtonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { layout } }
              disabled={ isDisabled || isAdding }
            >
                <span>{ isAdding ? __('Adding...') : __('Add to cart') }</span>
                { this.renderCartIcon() }
            </button>
        );
    }
}

export default AddToCartComponent;
