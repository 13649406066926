/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const ValidationInputTypeText = {
    alpha: 'alpha',
    alphaSpace: 'alphaSpace',
    alphaNumeric: 'alphaNumeric',
    alphaDash: 'alphaDash',
    url: 'url',
};

export const ValidationInputTypeNumber = {
    numeric: 'numeric',
    numericDash: 'numericDash',
    integer: 'integer',
    decimal: 'decimal',
    natural: 'natural',
    naturalNoZero: 'naturalNoZero',
};

export const ValidationInputTypeInput = {
    email: 'email',
    password: 'password',
    date: 'date',
    phone: 'phone',
    emailList: 'emailList',
    postcode: 'postcode',
    vat: 'vat',
};

export const ValidationInputType = {
    ...ValidationInputTypeText,
    ...ValidationInputTypeNumber,
    ...ValidationInputTypeInput,
};

export const VALIDATION_MESSAGES = {
    //#region VALIDATION RULE MSG
    isRequired: __('This field is required!'),
    match: __('Incorrect input!'),
    range: __('Value is out of range!'), // Range values are also in Validator.js as they require args
    group: __('Field contains issues!'),
    fileExtension: __('Incorrect File extension upload!'),
    //#endregion

    //#region VALIDATION RULE MSG
    [ValidationInputType.alpha]: __('Incorrect input! Only letters allowed!'),
    [ValidationInputType.alphaSpace]: __('Incorrect input! Only words allowed!'),
    [ValidationInputType.alphaNumeric]: __('Incorrect input! Alpha-Numeric value required!'),
    [ValidationInputType.alphaDash]: __('Incorrect input! Alpha-Dash value required!'),
    [ValidationInputType.url]: __('Incorrect input! URL required!'),
    [ValidationInputType.numeric]: __('Incorrect input! Numeric value required!'),
    [ValidationInputType.numericDash]: __('Incorrect input! Numeric-Dash value required!'),
    [ValidationInputType.integer]: __('Incorrect input! Integer required!'),
    [ValidationInputType.natural]: __('Incorrect input! Natural number required!'),
    [ValidationInputType.naturalNoZero]: __('Incorrect input!'),
    [ValidationInputType.email]: __('Incorrect email format!'),
    [ValidationInputType.emailList]: __('Incorrect list of emails!'),
    [ValidationInputType.date]: __('Incorrect date input!'),
    [ValidationInputType.password]: __('Incorrect password input!'),
    [ValidationInputType.phone]: __('Incorrect phone input!'),
    [ValidationInputType.postcode]: __('Incorrect postcode!'),
    [ValidationInputType.vat]: __('Incorrect vat number!'),
    //#endregion
};

export const plRegexp = 'a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\-\\s';
export const VALIDATION_RULES = {
    // Text
    [ValidationInputType.alpha]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [ValidationInputType.alphaSpace]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [ValidationInputType.alphaNumeric]: /^[a-z0-9]+$/i,
    [ValidationInputType.alphaDash]: /^[a-z0-9_\\-]+$/i,
    [ValidationInputType.url]: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[\d]+)?(\/|\/([\w#!:.?+=&%@\-\\/]))?$/,

    // Numbers
    [ValidationInputType.numeric]: /^[\d]+$/,
    [ValidationInputType.numericDash]: /^[\d\-\s]+$/,
    [ValidationInputType.integer]: /^\\-?[\d]+$/,
    [ValidationInputType.decimal]: /^\d*\.?\d*$/,
    [ValidationInputType.natural]: /^[\d]+$/i,
    [ValidationInputType.naturalNoZero]: /^[1-9][\d]*$/i,

    // Inputs
    // eslint-disable-next-line max-len
    [ValidationInputType.email]: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
    [ValidationInputType.date]: /\d{4}-\d{1,2}-\d{1,2}/,
    [ValidationInputType.postcode]: /^\d{2}-\d{3}$/,
    [ValidationInputType.vat]: /(PL)*[\d]{10}/,
    [ValidationInputType.phone]: /^([\\+0-9\s]{4})?[(]?[\d]{3}[)]?[-\s\\.]?[\d]{3}[-\s\\.]?[\d]{3,6}$/im,
    // eslint-disable-next-line max-len
    [ValidationInputType.emailList]: /^(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+([,\s]+(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
};

export const COUNTRY_POST_CODE_REGEX_LIST_FORMAT = {
    PL: {
        countryPostCodeLength: 6,
        exampleFormat: '00-000',
        regex: /^[0-9]{2}-[0-9]{3}$/,
    },
    HR: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    EE: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    FR: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    DE: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    AT: {
        countryPostCodeLength: 4,
        exampleFormat: '0000',
        regex: /^[0-9]{4}$/,
    },
    GR: {
        countryPostCodeLength: 6,
        exampleFormat: '000 00',
        regex: /^[0-9]{3}\s{1}[0-9]{2}$/,
    },
    IT: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    LV: {
        countryPostCodeLength: 7,
        exampleFormat: 'LV-0000',
        regex: /^LV-[0-9]{4}$/,
    },
    LT: {
        countryPostCodeLength: 8,
        exampleFormat: 'LT-00000',
        regex: /^LT-[0-9]{5}$/,
    },
    NL: {
        countryPostCodeLength: 6,
        exampleFormat: '0000AB',
        regex: /^[0-9]{4}[A-Z]{2}$/,
    },
    PT: {
        countryPostCodeLength: 8,
        exampleFormat: '0000-000',
        regex: /^[0-9]{4}-[0-9]{3}$/,
    },
    SK: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    CH: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    ES: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
};
