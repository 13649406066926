/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { COUNTRY_POST_CODE_REGEX_LIST_FORMAT } from './Config';

export * from 'SourceUtil/Validator/Validator';

/** @namespace Poprawa/Util/Validator/validateCountryPostCode */
export const validateCountryPostCode = (value, countryId) => {
    const countryPostCodeData = COUNTRY_POST_CODE_REGEX_LIST_FORMAT[countryId];

    return countryPostCodeData?.regex.test(value)
        ? true : __(
            'Please enter the valid post code in %s format',
            countryPostCodeData?.exampleFormat,
        );
};
