/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import ProductLabels from 'Component/ProductLabels';
import Image from 'SourceComponent/Image';
import { ImageRatio } from 'SourceComponent/Image/Image.type';
import { ProductCardComponent as SourceProductCardComponent } from 'SourceComponent/ProductCard/ProductCard.component';
import { getPrice } from 'Util/Product/Extract';

import './ProductCard.override.style';

/**
 * Product card
 * @class ProductCard
 * @namespace Poprawa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderButtonsVisibleOnMobile() {
        const { device } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <div block="ProductCard" elem="MobileActions">
                { this.renderProductActions() }
            </div>
        );
    }

    renderPicture(mix = {}) {
        const {
            product: {
                id, name, sf_labels = '', price_range,
            }, thumbnail, onLoad,
        } = this.props;

        const { price: { discount } } = getPrice(price_range);

        return (
            <>
                <ProductLabels
                  sf_labels={ sf_labels }
                  discount={ discount }
                />
                <Image
                  imageRef={ this.imageRef }
                  src={ thumbnail }
                  alt={ name }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                  isPlaceholder={ !id }
                  onImageLoad={ onLoad }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderCardContent() {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                        { this.renderButtonsVisibleOnMobile() }
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderName(false) }
                        { this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div>
                </div>
            ))
        );
    }
}

export default ProductCardComponent;
